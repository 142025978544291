<template lang="pug">
TableV2(
  :actions="actions"
  :headers="headers"
  :isLoading="isLoading"
  :items="items.results"
  :currentPage="items.current"
  :pageCount="items.count"
  :querySearch="getListQualificationStatementFromPackage"
)
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'QualificationStatementFromPackage',
  props: {
    statement: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      headers: [
        { value: 'number', text: this.$t('number') },
        { value: 'created_at', text: this.$t('createDate'), sortable: true },
        { value: 'port', text: this.$t('port'), sortable: true },
        { value: 'type_document', text: this.$t('typeDoc'), sortable: true },
        { value: 'rank', text: this.$t('rank'), sortable: true },
        { value: 'list_positions', text: this.$t('position'), sortable: true },
        { value: 'is_payed', text: this.$t('payment'), sortable: true },
        { value: 'status_document', text: this.$t('status'), sortable: true },
        { value: 'event', text: this.$t('actions'), class: 'mw-0' }
      ],
      actions: [
        {
          id: 1,
          action: ({ id, sailor }) => this.$router.push({ name: 'qualification-statements-info', params: { id: sailor.id, documentID: id }, query: { ...this.$route.query } }),
          color: 'blue',
          name: 'mdi-arrow-right',
          tooltip: 'tooltip.go',
          buttonOptions: { xSmall: false, icon: true }
        }
      ]
    }
  },
  computed: {
    ...mapState({
      items: state => state.statement.statementQualificationFromPackage,
      isLoading: state => state.statement.isLoading
    })
  },
  methods: {
    ...mapActions(['getListQualificationStatementFromPackage'])
  }
}
</script>
